<template>
  <validation-observer ref="form">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <h3>Ventas {{ Number(fair.year) - 2 }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12">
            <validation-provider vid="sales" v-slot="{ errors }" name="Ventas año anterior" rules="required">
              <v-select
                v-model="model.lastYear.saleRangeId"
                :error-messages="errors"
                :items="saleRanges"
                item-text="name"
                item-value="id"
                outlined
                dense
                label="Ventas*"
              ></v-select>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12">
            <validation-provider
              vid="lastYear.nationalPercentage"
              v-slot="{ errors }"
              name="Ventas Nacionales en porcentaje"
              rules="required|min_value:0|max_value:100"
            >
              <v-text-field
                v-model="model.lastYear.nationalPercentage"
                @input="calculatePercentageLastYear"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Ventas Nacionales en porcentaje*"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12">
            <validation-provider
              vid="lastYear.internationalPercentage"
              v-slot="{ errors }"
              name="Ventas Internacionales en porcentaje"
              rules="required|min_value:0|max_value:100"
            >
              <v-text-field
                v-model="model.lastYear.internationalPercentage"
                type="text"
                outlined
                dense
                disabled
                :error-messages="errors"
                label="Ventas Internacionales en porcentaje*"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <h3>Ventas {{ Number(fair.year) - 1 }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12">
            <validation-provider vid="sales" v-slot="{ errors }" name="Ventas año actual" rules="required">
              <v-select
                v-model="model.currentYear.saleRangeId"
                :error-messages="errors"
                :items="saleRanges"
                item-text="name"
                item-value="id"
                outlined
                dense
                label="Ventas*"
              ></v-select>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12">
            <validation-provider
              vid="currentYear.nationalPercentage"
              v-slot="{ errors }"
              name="Ventas Nacionales en porcentaje"
              rules="required|min_value:0|max_value:100"
            >
              <v-text-field
                v-model="model.currentYear.nationalPercentage"
                @input="calculatePercentageCurrentYear"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Ventas Nacionales en porcentaje*"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12">
            <validation-provider
              vid="currentYear.internationalPercentage"
              v-slot="{ errors }"
              name="Ventas Internacionales en porcentaje"
              rules="required|min_value:0|max_value:100"
            >
              <v-text-field
                v-model="model.currentYear.internationalPercentage"
                type="text"
                outlined
                dense
                disabled
                :error-messages="errors"
                label="Ventas Internacionales en porcentaje*"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <h3>Ventas Proyectadas {{ fair.year }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <validation-provider vid="sales" v-slot="{ errors }" name="Ventas año posterior" rules="required">
              <v-select
                v-model="model.nextYear.saleRangeId"
                :error-messages="errors"
                :items="saleRanges"
                item-text="name"
                item-value="id"
                outlined
                dense
                label="Ventas*"
              ></v-select>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <validation-provider
              vid="nextYear.nationalPercentage"
              v-slot="{ errors }"
              name="Ventas Nacionales en porcentaje"
              rules="required|min_value:0|max_value:100"
            >
              <v-text-field
                v-model="model.nextYear.nationalPercentage"
                @input="calculatePercentageNextYear"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Ventas Nacionales en porcentaje*"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <validation-provider
              vid="nextYear.internationalPercentage"
              v-slot="{ errors }"
              name="Ventas Internacionales en porcentaje"
              rules="required|min_value:0|max_value:100"
            >
              <v-text-field
                v-model="model.nextYear.internationalPercentage"
                type="text"
                outlined
                dense
                disabled
                :error-messages="errors"
                label="Ventas Internacionales en porcentaje*"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <v-btn color="primary" class="mr-2 text-none elevation-0" @click="saveStep8">
          <span v-if="!hasPermission('EDIT_BOOKING')">Siguiente</span>
          <span v-if="hasPermission('EDIT_BOOKING')">Guardar</span>
        </v-btn>
        <v-btn color="default" class="mr-2 text-none elevation-0" @click="$emit('nextStep',7)">
          Anterior
        </v-btn>
      </div>
    </div>
  </validation-observer>
</template>

<script>

import { BOOKINGS_URL, SALES_URL } from '@/constants/ServicesConstants'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import eventHub from '@/eventhub'
import sessionMixin from '@/mixins/sessionMixin'

export default {
  created () {
    eventHub.$on('step8', async () => {
      try {
        this.showLoader()
        await this.getSales()
        await this.getStep8()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    })
  },
  data () {
    return {
      model: {
        currentYear: {
          internationalPercentage: null,
          nationalPercentage: null,
          saleRangeId: null,
          year: null
        },
        lastYear: {
          internationalPercentage: null,
          nationalPercentage: null,
          saleRangeId: null,
          year: null
        },
        nextYear: {
          internationalPercentage: null,
          nationalPercentage: null,
          saleRangeId: null,
          year: null
        }
      },
      saleRanges: []
    }
  },
  methods: {
    calculatePercentageCurrentYear () {
      if (this.model.currentYear.nationalPercentage !== '' && !isNaN(this.model.currentYear.nationalPercentage)) {
        this.model.currentYear.internationalPercentage = 100 - this.model.currentYear.nationalPercentage
      }
    },
    calculatePercentageLastYear () {
      if (this.model.lastYear.nationalPercentage !== '' && !isNaN(this.model.lastYear.nationalPercentage)) {
        this.model.lastYear.internationalPercentage = 100 - this.model.lastYear.nationalPercentage
      }
    },
    calculatePercentageNextYear () {
      if (this.model.nextYear.nationalPercentage !== '' && !isNaN(this.model.nextYear.nationalPercentage)) {
        this.model.nextYear.internationalPercentage = 100 - this.model.nextYear.nationalPercentage
      }
    },
    async getSales () {
      const response = await this.get(SALES_URL)
      this.saleRanges = response.data
    },
    async getStep8 () {
      const response = await this.get(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-8')
      if (response.data.length) {
        this.model.lastYear = response.data[0]
        this.model.currentYear = response.data[1]
        this.model.nextYear = response.data[2]
      }
    },
    saveStep8 () {
      this.executeWithFormValidation(() => {
        this.model.currentYear.year = Number(this.fair.year) - 1
        this.model.lastYear.year = Number(this.fair.year) - 2
        this.model.nextYear.year = Number(this.fair.year)
        this.create(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-8', this.model).then(() => {
          this.$emit('nextStep', 9)
        })
      })
    }
  },
  mixins: [crudServiceMixin, loaderMixin, notificationMixin, formOperationsMixin, sessionMixin],
  name: 'commercialInfo',
  props: {
    bookingId: {
      default: null,
      type: String
    },
    fair: {
      type: Object,
      default: null
    }
  }
}
</script>
