<template>
  <div>
    <validation-observer ref="form">
      <div class="row">
        <div class="col-12 pt-0 pb-0">
          <p class="font-bold">Su empresa ha participado en ruedas de negocios nacionales?*.</p>
          <v-container
            class="px-0 pt-0 pb-0 ml-2"
            fluid
          >
            <validation-provider vid="nationalBusinessConferenceParticipation" v-slot="{ errors }"
                                 name="participación rueda negocios nacionales" rules="required">
              <v-radio-group
                v-model="model.nationalBusinessConferenceParticipation"
                column
                :error-messages="errors"
              >
                <v-radio
                  color="primary"
                  label="Si"
                  :value="1"
                ></v-radio>
                <v-radio
                  label="No"
                  :value="0"
                ></v-radio>
              </v-radio-group>
            </validation-provider>
          </v-container>
        </div>
      </div>

      <div class="row">
        <div class="col-12 pt-0 pb-0">
          <p class="font-bold">Su empresa ha participado en ruedas de negocios internacionales?*.</p>
          <v-container
            class="px-0 pt-0 pb-0 ml-2"
            fluid
          >
            <validation-provider vid="internationalBusinessConferenceParticipation" v-slot="{ errors }"
                                 name="participación rueda negocios internacionales" rules="required">
              <v-radio-group
                v-model="model.internationalBusinessConferenceParticipation"
                column
                :error-messages="errors"
              >
                <v-radio
                  label="Si"
                  :value="1"
                ></v-radio>
                <v-radio
                  label="No"
                  :value="0"
                ></v-radio>
              </v-radio-group>
            </validation-provider>
          </v-container>
        </div>
      </div>

      <div class="row">
        <div class="col-12 pt-0 pb-0">
          <p class="font-bold">Su empresa realiza operaciones de:</p>
          <v-checkbox
            class="ml-2 pt-0 pb-0"
            color="primary"
            v-model="model.imports"
            :label="'Importación'"
          ></v-checkbox>
          <v-checkbox
            class="ml-2 pt-0 pb-0"
            color="primary"
            @change="onExports"
            v-model="model.exports"
            :label="'Exportación'"
          ></v-checkbox>
        </div>
      </div>
      <div class="row" v-if="model.exports">
        <div class="col-12">
          <p class="font-bold">Ingrese los paises de exportacion y porcentajes (máximo 10).</p>
          <div class="row" v-for="(exportCountry, index) in model.exportCountries" :key="index">
            <div class="col-8 col-md-5 pt-0 pb-0">
              <validation-provider
                v-slot="{ errors }"
                name="Porcentaje"
                vid="company"
                rules="required|min_value:0|max_value:100"
              >
                <v-text-field
                  v-model="exportCountry.percentage"
                  type="text"
                  outlined
                  dense
                  :error-messages="errors"
                  label="Porcentaje"
                  required
                ></v-text-field>
              </validation-provider>
            </div>
            <div class="col-8 col-md-5 pt-0 pb-0">
              <validation-provider vid="countries" v-slot="{ errors }" name="País" rules="required">
                <v-select
                  v-model="exportCountry.countryId"
                  :error-messages="errors"
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  label="País*"
                ></v-select>
              </validation-provider>
            </div>
            <div v-if="index !== 0" class="col-4 col-md-2 pt-0 pb-0 pr-1">
              <v-btn color="primary" class="mr-2" @click="deleteCountry(index)">
                <v-icon
                  center
                  dark
                  size="15">
                  fa-trash
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div class="row">
            <div class="col-12 pt-0">
              <v-btn color="secondary" class="mr-2 text--primary elevation-0 text-none" @click="addCountry">
                Agregar
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-0 pb-0">
          <v-btn color="primary" class="mr-2 text-none elevation-0" @click="saveStep11">
            Guardar
          </v-btn>
          <v-btn color="default" class="mr-2 text-none elevation-0" @click="$emit('nextStep',10)">
            Anterior
          </v-btn>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>

import { BOOKINGS_URL, ADDRESSES_URL } from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import eventHub from '@/eventhub'

export default {
  created () {
    eventHub.$on('step11', async () => {
      try {
        this.showLoader()
        await this.getStep11()
        await this.getCountries()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    })
  },
  data () {
    return {
      countries: [],
      model: {
        exportCountries: [{}],
        internationalBusinessConferenceParticipation: null,
        nationalBusinessConferenceParticipation: null,
        imports: null,
        exports: null
      }
    }
  },
  methods: {
    addCountry () {
      if (this.model.exportCountries.length < 10) {
        this.model.exportCountries.push({})
      }
    },
    deleteCountry (position) {
      this.model.exportCountries = this.model.exportCountries.filter((item, index) => index !== position)
    },
    async getStep11 () {
      const response = await this.get(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-11')
      this.model.internationalBusinessConferenceParticipation = response.data.internationalBusinessConferenceParticipation
        ? Number(response.data.internationalBusinessConferenceParticipation) : null
      this.model.nationalBusinessConferenceParticipation = response.data.nationalBusinessConferenceParticipation
        ? Number(response.data.nationalBusinessConferenceParticipation) : null
      this.model.imports = response.data.imports ? Number(response.data.imports) : null
      this.model.exports = response.data.exports ? Number(response.data.exports) : null
      this.model.exportCountries = response.data.export_countries.map(item => {
        return {
          countryId: item.countryId,
          percentage: item.percentage
        }
      })
    },
    async getCountries () {
      const response = await this.get(ADDRESSES_URL + '/countries')
      this.countries = response.data
    },
    saveStep11 () {
      this.executeWithFormValidation(() => {
        this.create(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-11', this.model).then(() => {
          this.$emit('nextStep', 12)
        })
      })
    },
    onExports () {
      if (this.model.exports) {
        this.model.exportCountries = [{}]
      } else {
        this.model.exportCountries = null
      }
    }
  },
  mixins: [loaderMixin, notificationMixin, crudServiceMixin, formOperationsMixin],
  name: 'ParticipationBusinessConference',
  props: {
    bookingId: {
      default: null,
      type: String
    }
  }
}
</script>
