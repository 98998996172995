<template>
  <validation-observer ref="form">
    <div class="px-7 row">
      <div class="col-12">
        <file-chooser
          ref="fileChooser"
          @input="onLoadCompanyLogo"
          :options="{
          width: '400px',
          height: '200px',
          description: 'Haz clic aquí para cargar el logo de la empresa',
          regex: /\.(jpg|jpeg|png|gif)$/,
          error: 'Solo se permiten imagenes',
          fileSize: 2
        }">
        </file-chooser>
        <v-tooltip bottom class="col-auto">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="onDeleteImage"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>Eliminar Imagen</span>
        </v-tooltip>
        <a
          :href="logoUrl"
          target="_blank"
          v-if="roleId !== 3 && roleId !== 5 && logoUrl"
        >Descargar</a>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <validation-provider
          vid="constitutionYear"
          v-slot="{ errors }"
          name="Año de constitución"
          rules="required|max:80"
        >
          <v-text-field
            v-model="model.constitutionYear"
            type="number"
            outlined
            dense
            :error-messages="errors"
            label="Año de constitución*"
            required
          ></v-text-field>
        </validation-provider>
      </div>

      <div class="col-12 col-md-6">
        <validation-provider vid="companySizeId" v-slot="{ errors }" name="Tamaño" rules="required">
          <v-select
            v-model="model.companySizeId"
            :error-messages="errors"
            :items="companySizes"
            item-text="name"
            item-value="id"
            outlined
            dense
            label="Tamaño*"
          ></v-select>
        </validation-provider>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <validation-provider
          vid="employeesAmount"
          v-slot="{ errors }"
          name="Número de empleados"
          rules="required|max:80"
        >
          <v-text-field
            v-model="model.employeesAmount"
            type="number"
            outlined
            dense
            :error-messages="errors"
            label="Número de empleados*"
            required
          ></v-text-field>
        </validation-provider>
      </div>

      <div class="col-12 col-md-6">
        <validation-provider
          vid="customersAmount"
          v-slot="{ errors }"
          name="Número de clientes"
          rules="required|max:80"
        >
          <v-text-field
            v-model="model.customersAmount"
            type="number"
            outlined
            dense
            :error-messages="errors"
            label="Número de clientes*"
            required
          ></v-text-field>
        </validation-provider>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <v-btn color="primary" class="mr-2 text-none elevation-0" @click="saveStep3">
          <span v-if="!hasPermission('EDIT_BOOKING')">Siguiente</span>
          <span v-if="hasPermission('EDIT_BOOKING')">Guardar</span>
        </v-btn>
        <v-btn color="default" class="mr-2 text-none elevation-0" @click="$emit('nextStep',2)">
          Anterior
        </v-btn>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import FileChooser from '@/components/file-chooser/FileChooser'
import { BOOKINGS_URL, COMPANY_SIZES } from '@/constants/ServicesConstants'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import eventHub from '@/eventhub'
import sessionMixin from '@/mixins/sessionMixin'

export default {
  name: 'AdditionalCompanyInfo',
  props: {
    bookingId: {
      default: null,
      type: String
    }
  },
  computed: {
    logoUrl () {
      return (this.model.logoUrl) ? process.env.VUE_APP_API_IMAGES_URL + this.model.logoUrl : null
    }
  },
  async created () {
    eventHub.$on('step3', async () => {
      try {
        this.showLoader()
        await this.getCompanySizes()
        await this.getStep3()
        this.getRoleId()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    })
  },
  methods: {
    onLoadCompanyLogo (file) {
      this.logoFile = file
    },
    async getCompanySizes () {
      const response = await this.get(COMPANY_SIZES)
      this.companySizes = response.data
    },
    async getStep3 () {
      const response = await this.get(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-3')
      this.model = response.data
      if (this.model.logoUrl) {
        this.$nextTick(() => {
          this.$refs.fileChooser.setImage(this.model.logoUrl)
        })
      }
    },
    saveStep3 () {
      this.executeWithFormValidation(() => {
        if (this.logoFile || this.model.logoUrl) {
          const formData = this.getFormData()
          formData.append('logoFile', this.logoFile)
          this.create(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-3', formData).then(() => {
            this.$emit('nextStep', 4)
          })
        } else {
          this.$refs.fileChooser.setError('El logo de la empresa es requerido')
        }
      })
    },
    onDeleteImage () {
      this.logoFile = null
      this.model.logoUrl = null
      this.$refs.fileChooser.setImage(null)
    },
    getRoleId () {
      const user = this.getUser()
      this.roleId = user.roleId
    }
  },
  components: { FileChooser },
  mixins: [crudServiceMixin, loaderMixin, notificationMixin, formOperationsMixin, sessionMixin],
  data () {
    return {
      logoFile: null,
      companySizes: [],
      model: {
        constitutionYear: null,
        companySizeId: null,
        employeesAmount: null,
        customersAmount: null,
        logoUrl: null
      },
      roleId: null
    }
  }
}
</script>
