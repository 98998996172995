<template>
  <validation-observer ref="form">
    <div v-if="model.duplicatedInfo">
      <v-alert type="error">
        <div class="text-h6">
        Tu compañía ya ha participado en ferias previas, hemos recopilado y precargado en este formulario la información más actualizada. Verifícala con cuidado y actualízala si es necesario.
        </div>
      </v-alert>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <h3>Representante Legal</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <validation-provider
              vid="legalRepresentativeName"
              v-slot="{ errors }"
              name="Nombre"
              rules="required|max:80"
            >
              <v-text-field
                v-model="model.legalRepresentativeName"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Nombre*"
                required
              ></v-text-field>
            </validation-provider>
          </div>

          <div class="col-12 col-md-6">
            <validation-provider
              vid="legalRepresentative.email"
              v-slot="{ errors }"
              name="Correo electrónico"
              rules="required|max:255|email"
            >
              <v-text-field
                v-model="model.legalRepresentativeEmail"
                type="email"
                outlined
                dense
                :error-messages="errors"
                label="Correo electrónico*"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <validation-provider vid="legalRepresentativeIdentificationType" v-slot="{ errors }"
                                 name="Tipo de identificación" rules="required">
              <v-select
                v-model="model.legalRepresentativeIdentificationType"
                :error-messages="errors"
                :items="documentTypes"
                item-text="description"
                item-value="code"
                outlined
                dense
                label="Tipo de identificación*"
              ></v-select>
            </validation-provider>
          </div>

          <div class="col-12 col-md-6">
            <validation-provider
              vid="legalRepresentativeIdentification"
              v-slot="{ errors }"
              name="Identificación"
              rules="required|max:80"
            >
              <v-text-field
                v-model="model.legalRepresentativeIdentification"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Identificación*"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="d-flex flex-wrap">
              <div class="flex-grow-1">
                <validation-provider
                  vid="legalRepresentativePhone"
                  v-slot="{ errors }"
                  name="Teléfono"
                  :rules="{required:true, phone: true}"
                >
                  <vue-tel-input-vuetify
                    label="Teléfono*"
                    mode="international"
                    :inputOptions="{showDialCode:false}"
                    :error-messages="errors"
                    class="flex-wrap"
                    required
                    outlined
                    dense
                    v-model="model.legalRepresentativePhone"
                    :preferred-countries="['CO', 'US']"
                  ></vue-tel-input-vuetify>
                </validation-provider>
              </div>
              <div class="flex-grow-1">
                <validation-provider
                  vid="legalRepresentativePhoneExtension"
                  v-slot="{ errors }"
                  name="Extensión"
                  rules="integer"
                >
                  <v-text-field
                    v-model="model.legalRepresentativePhoneExtension"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Extensión"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <h3>Gerente de mercadeo</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <validation-provider
              vid="CMOName"
              v-slot="{ errors }"
              name="Nombre"
              rules="max:80"
            >
              <v-text-field
                v-model="model.CMOName"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Nombre"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <validation-provider
              vid="CMOPositionDetail"
              v-slot="{ errors }"
              name="Cargo"
              rules="max:255"
            >
              <v-text-field
                v-model="model.CMOPositionDetail"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Cargo"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <validation-provider
              vid="CMOEmail"
              v-slot="{ errors }"
              name="Correo electrónico"
              rules="max:255|email"
            >
              <v-text-field
                v-model="model.CMOEmail"
                type="email"
                outlined
                dense
                :error-messages="errors"
                label="Correo electrónico"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-flex flex-wrap">
              <div class="flex-grow-1">
                <validation-provider
                  vid="CMOPhone"
                  v-slot="{ errors }"
                  :rules="{phone:true}"
                  name="Teléfono"
                >
                  <vue-tel-input-vuetify
                    label="Teléfono"
                    mode="international"
                    :error-messages="errors"
                    required
                    outlined
                    dense
                    v-model="model.CMOPhone"
                    :preferred-countries="['CO', 'US']"
                  ></vue-tel-input-vuetify>
                </validation-provider>
              </div>
              <div class="flex-grow-1">
                <validation-provider
                  vid="CMOPhoneExtension"
                  v-slot="{ errors }"
                  name="Extensión"
                  rules="integer"
                >
                  <v-text-field
                    v-model="model.CMOPhoneExtension"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Extensión"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <h3>Área de compras</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <validation-provider
              vid="shoppingAreaName"
              v-slot="{ errors }"
              name="Nombre"
              rules="max:80"
            >
              <v-text-field
                v-model="model.shoppingAreaName"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Nombre"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <validation-provider
              vid="shoppingAreaPositionDetail"
              v-slot="{ errors }"
              name="Cargo"
              rules="max:255"
            >
              <v-text-field
                v-model="model.shoppingAreaPositionDetail"
                type="text"
                outlined
                dense
                :error-messages="errors"
                label="Cargo"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <validation-provider
              vid="shoppingAreaEmail"
              v-slot="{ errors }"
              name="Correo electrónico"
              rules="max:255|email"
            >
              <v-text-field
                v-model="model.shoppingAreaEmail"
                type="email"
                outlined
                dense
                :error-messages="errors"
                label="Correo electrónico"
                required
              ></v-text-field>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-flex flex-wrap">
              <div class="flex-grow-1">
                <validation-provider
                  vid="shoppingAreaPhone"
                  v-slot="{ errors }"
                  :rules="{phone:true}"
                  name="Teléfono"
                >
                  <vue-tel-input-vuetify
                    label="Teléfono"
                    mode="international"
                    :error-messages="errors"
                    class="flex-wrap"
                    required
                    outlined
                    dense
                    v-model="model.shoppingAreaPhone"
                    :preferred-countries="['CO', 'US']"
                  ></vue-tel-input-vuetify>
                </validation-provider>
              </div>
              <div class="flex-grow-1">
                <validation-provider
                  vid="shoppingAreaPhoneExtension"
                  v-slot="{ errors }"
                  name="Extensión"
                  rules="integer"
                >
                  <v-text-field
                    v-model="model.shoppingAreaPhoneExtension"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Extensión"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <v-btn color="primary" class="mr-2 text-none elevation-0" @click="saveStep2">
          <span v-if="!hasPermission('EDIT_BOOKING')">Siguiente</span>
          <span v-if="hasPermission('EDIT_BOOKING')">Guardar</span>
        </v-btn>
        <v-btn color="default" class="mr-2 text-none elevation-0 " @click="$emit('nextStep',1)">
          Anterior
        </v-btn>
      </div>
    </div>
  </validation-observer>
</template>

<script>

import { BOOKINGS_URL, DOCUMENT_TYPE_URL } from '@/constants/ServicesConstants'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import eventHub from '@/eventhub'
import sessionMixin from '@/mixins/sessionMixin'

export default {
  name: 'OtherInterestContacts',
  props: {
    bookingId: {
      default: null,
      type: String
    }
  },
  created () {
    eventHub.$on('step2', async () => {
      try {
        this.showLoader()
        await this.getDocumentTypes()
        await this.getStep2()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    })
  },
  methods: {
    async getDocumentTypes () {
      const response = await this.get(DOCUMENT_TYPE_URL)
      this.documentTypes = response.data.filter(documentType => {
        return documentType.code !== 'NIT'
      })
    },
    async getStep2 () {
      const response = await this.get(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-2')
      this.model = response.data
    },
    saveStep2 () {
      this.executeWithFormValidation(() => {
        this.create(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-2', this.model).then(() => {
          this.$emit('nextStep', 3)
        })
      })
    }
  },
  mixins: [crudServiceMixin, loaderMixin, notificationMixin, formOperationsMixin, sessionMixin],
  components: {},
  data () {
    return {
      documentTypes: [],
      model: {
        CMOEmail: null,
        CMOId: null,
        CMOMobilePhone: null,
        CMOName: null,
        CMOPhone: null,
        CMOPhoneExtension: null,
        CMOPositionDetail: null,
        duplicatedInfo: null,
        legalRepresentativeIdentificationType: null,
        legalRepresentativeIdentification: null,
        legalRepresentativeEmail: null,
        legalRepresentativeId: null,
        legalRepresentativeMobilePhone: null,
        legalRepresentativeName: null,
        legalRepresentativePhone: null,
        legalRepresentativePhoneExtension: null,
        legalRepresentativePositionDetail: null,
        shoppingAreaEmail: null,
        shoppingAreaId: null,
        shoppingAreaMobilePhone: null,
        shoppingAreaName: null,
        shoppingAreaPhone: null,
        shoppingAreaPhoneExtension: null,
        shoppingAreaPositionDetail: null
      }
    }
  }
}
</script>
<style lang="scss">
.alert-info {
  border: 1px solid rgb(251,238,213);
  background-color: rgb(252,248,227);
  color: rgb(192,152,83);
  padding-left: 10px;
  font-size: 120%;
}
</style>
