<template>
  <validation-observer ref="form">
    <div v-if="model">
      <div class="row">
        <div class="col-12">
          <h3>Datos de solicitud de stands</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-3" v-if="hasPermission('EDIT_BOOKING')">
          <validation-provider
            v-slot="{ errors }"
            vid="companyIdentification"
            :name="rutLabel"
            rules="required|nit"
          >
            <v-text-field
              v-if="hasPermission('EDIT_BOOKING')"
              v-model="model.companyIdentification"
              type="text"
              outlined
              dense
              :error-messages="errors"
              :label="rutLabel + ' (Sin código de verificación)'"
              required
            ></v-text-field>
          </validation-provider>
        </div>
        <div class="col-12 col-md-3" v-if="!hasPermission('EDIT_BOOKING')">
          <span class="font-weight-bold">{{ rutLabel }}:</span>
          {{ model.companyIdentification }}
        </div>
        <div class="col-12 col-md-3" v-if="hasPermission('EDIT_BOOKING')">
          <validation-provider
            v-slot="{ errors }"
            vid="companyBusinessName"
            name="Razón Social"
            rules="required|max:80"
          >
            <v-text-field
              v-model="model.companyBusinessName"
              type="text"
              outlined
              dense
              :error-messages="errors"
              label="Razón social"
              required
            ></v-text-field>
          </validation-provider>
        </div>
        <div class="col-12 col-md-3" v-if="!hasPermission('EDIT_BOOKING')">
          <span class="font-weight-bold">Razón social:</span>
          {{ model.companyBusinessName }}
        </div>
        <div class="col-12 col-md-3" v-if="hasPermission('EDIT_BOOKING')">
          <validation-provider
            v-slot="{ errors }"
            vid="companyName"
            name="Nombre comercial"
            rules="required|max:80"
          >
            <v-text-field
              v-model="model.companyName"
              type="text"
              outlined
              dense
              :error-messages="errors"
              label="Nombre comercial"
              required
            ></v-text-field>
          </validation-provider>
        </div>
        <div class="col-12 col-md-3" v-if="!hasPermission('EDIT_BOOKING')">
          <span class="font-weight-bold">Nombre comercial:</span>
          {{ model.companyName }}
        </div>
        <div class="col-12 col-md-3" v-if="hasPermission('EDIT_BOOKING')">
          <validation-provider
            vid="companyEmail"
            v-slot="{ errors }"
            name="Correo corporativo"
            rules="email"
          >
            <v-text-field
              v-model="model.companyEmail"
              type="email"
              outlined
              dense
              :error-messages="errors"
              label="Correo corporativo"
              required
            ></v-text-field>
          </validation-provider>
        </div>
        <div class="col-12 col-md-3" v-if="!hasPermission('EDIT_BOOKING')">
          <span class="font-weight-bold">Correo corporativo:</span>
          {{ model.companyEmail }}
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4" v-if="hasPermission('EDIT_BOOKING')">
          <validation-provider
            v-slot="{ errors }"
            vid="companyPhone"
            name="Teléfono de la empresa"
            :rules="{required: true,phone: true}"
          >
            <vue-tel-input-vuetify
              label="Teléfono de la empresa"
              :error-messages="errors"
              class="flex-wrap"
              mode="international"
              :inputOptions="{showDialCode:false}"
              required
              outlined
              dense
              :preferred-countries="['CO', 'US']"
              v-model="model.companyPhone"
            />
          </validation-provider>
        </div>
        <div class="col-12 col-md-3" v-if="!hasPermission('EDIT_BOOKING')">
          <span class="font-weight-bold">Teléfono de la empresa:</span>
          {{ model.companyPhone }}
        </div>

        <div class="col-12 col-md-4" v-if="hasPermission('EDIT_BOOKING')">
          <validation-provider
            v-slot="{ errors }"
            name="Dirección"
            vid="companyAddress"
            rules="required|max:80"
          >
            <v-text-field
              v-model="model.companyAddress"
              type="text"
              outlined
              dense
              :error-messages="errors"
              label="Dirección"
              required
            ></v-text-field>
          </validation-provider>
        </div>
        <div class="col-12 col-md-3" v-if="!hasPermission('EDIT_BOOKING')">
          <span class="font-weight-bold">Dirección:</span>
          {{ model.companyAddress }}
        </div>

        <div class="col-12 col-md-4" v-if="hasPermission('EDIT_BOOKING')">
          <validation-provider
            v-slot="{ errors }"
            name="Página Web"
            vid="companyWebSite"
            rules="max:80"
          >
            <v-text-field
              v-model="model.companyWebSite"
              type="text"
              outlined
              dense
              :error-messages="errors"
              label="Página Web"
              required
            ></v-text-field>
          </validation-provider>
        </div>
        <div class="col-12 col-md-3" v-if="!hasPermission('EDIT_BOOKING')">
          <span class="font-weight-bold">Página Web:</span>
          {{ model.companyWebSite }}
        </div>

      </div>

      <div class="row">
        <div class="col-12 col-md-4" v-if="hasPermission('EDIT_BOOKING')">
          <validation-provider vid="companyCountryId" v-slot="{ errors }" name="País" rules="required">
            <v-select
              v-model="model.companyCountryId"
              :error-messages="errors"
              :items="countries"
              @change="onChangeCountry"
              outlined
              dense
              item-text="name"
              item-value="id"
              label="País"
            />
          </validation-provider>
        </div>
        <div class="col-12 col-md-3" v-if="!hasPermission('EDIT_BOOKING')">
          <span class="font-weight-bold">País:</span>
          {{ model.companyCountryName }}
        </div>

        <div class="col-12 col-md-4" v-if="hasPermission('EDIT_BOOKING')">
          <validation-provider vid="companyDepartmentId" v-slot="{ errors }" name="Departamento" rules="required">
            <v-select
              v-model="model.companyDepartmentId"
              :error-messages="errors"
              :items="departments"
              @change="onChangeDepartment"
              outlined
              dense
              item-text="name"
              item-value="id"
              label="Departamento"
            />
          </validation-provider>
        </div>
        <div class="col-12 col-md-3" v-if="!hasPermission('EDIT_BOOKING')">
          <span class="font-weight-bold">Departamento:</span>
          {{ model.companyDepartmentName }}
        </div>

        <div class="col-12 col-md-4" v-if="hasPermission('EDIT_BOOKING')">
          <validation-provider vid="companyCityId" v-slot="{ errors }" name="Ciudad" rules="required">
            <v-select
              v-model="model.companyCityId"
              :error-messages="errors"
              :items="cities"
              outlined
              dense
              item-text="name"
              item-value="id"
              label="Ciudad"
            ></v-select>
          </validation-provider>
        </div>
        <div class="col-12 col-md-3" v-if="!hasPermission('EDIT_BOOKING')">
          <span class="font-weight-bold">Ciudad:</span>
          {{ model.companyCityName }}
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h3>Gerente de la empresa</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6" v-if="hasPermission('EDIT_BOOKING')">
          <validation-provider
            vid="CEOName"
            v-slot="{ errors }"
            name="Nombre"
            rules="required|max:80"
          >
            <v-text-field
              v-model="model.CEOName"
              type="text"
              outlined
              dense
              :error-messages="errors"
              label="Nombre"
              required
            ></v-text-field>
          </validation-provider>
        </div>
        <div class="col-12 col-md-6" v-if="!hasPermission('EDIT_BOOKING')">
          <span class="font-weight-bold">Nombre:</span> {{ model.CEOName }}
        </div>
        <div class="col-12 col-md-6" v-if="hasPermission('EDIT_BOOKING')">
          <validation-provider
            vid="CEOPositionDetail"
            v-slot="{ errors }"
            name="Cargo"
            rules="required|max:80"
          >
            <v-text-field
              v-model="model.CEOPositionDetail"
              type="text"
              outlined
              dense
              :error-messages="errors"
              label="Cargo"
              required
            ></v-text-field>
          </validation-provider>
        </div>
        <div class="col-12 col-md-6" v-if="!hasPermission('EDIT_BOOKING')">
          <span class="font-weight-bold">Cargo:</span>
          {{ model.CEOPositionDetail }}
        </div>
        <div class="col-12 col-md-6" v-if="hasPermission('EDIT_BOOKING')">
          <validation-provider
            v-slot="{ errors }"
            vid="CEOEmail"
            name="Correo electrónico"
            rules="required|email"
          >
            <v-text-field
              v-model="model.CEOEmail"
              type="text"
              outlined
              dense
              :error-messages="errors"
              label="Correo electrónico"
              required
            ></v-text-field>
          </validation-provider>
        </div>
        <div class="col-12 col-md-6" v-if="!hasPermission('EDIT_BOOKING')">
          <span class="font-weight-bold">Correo electrónico:</span>
          {{ model.CEOEmail }}
        </div>
        <div class="col-12 col-md-6" v-if="hasPermission('EDIT_BOOKING')">
          <div class="d-flex flex-wrap">
            <div class="flex-grow-1">
              <validation-provider
                v-slot="{ errors }"
                name="Teléfono"
                :rules="{required: true,phone:true}"
              >
                <vue-tel-input-vuetify
                  label="Teléfono"
                  placeholder=""
                  :error-messages="errors"
                  mode="international"
                  :inputOptions="{showDialCode:false}"
                  required
                  outlined
                  dense
                  v-model="model.CEOPhone"
                  :preferred-countries="['CO', 'US']"
                ></vue-tel-input-vuetify>
              </validation-provider>
            </div>
            <div class="flex-grow-1">
              <validation-provider
                v-slot="{ errors }"
                name="Extensión"
                vid="CEOPhoneExtension"
                rules="integer"
              >
                <v-text-field
                  v-model="model.CEOPhoneExtension"
                  type="text"
                  outlined
                  dense
                  :error-messages="errors"
                  label="Extensión"
                  required
                ></v-text-field>
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6" v-if="!hasPermission('EDIT_BOOKING')">
          <span class="font-weight-bold">Teléfono:</span> {{ model.CEOPhone }} -
          Ext {{ model.CEOPhoneExtension }}
        </div>
      </div>

      <div class="text-h5 red--text">
        <span class="font-bold">Nota:</span>
        Los <span class="font-italic">Contactos {{ appName }}</span>
        , son las personas que recibirán todas las comunicaciones para la participación en la Feria.  Por lo anterior, es importante que tengan la disponibilidad para leer y analizar la información enviada y en caso de ser necesario, responder oportunamente.
        <br>El primer y segundo contacto no pueden tener el mismo correo y deben ser únicos por reserva. (No deben haber sido usados en otra reserva de esta feria)
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-12">
              <h3>Primer contacto para {{ appName }}</h3>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <validation-provider
                v-slot="{ errors }"
                name="Nombre"
                vid="firstContactName"
                rules="required|max:80"
              >
                <v-text-field
                  v-model="model.firstContactName"
                  type="text"
                  outlined
                  dense
                  :error-messages="errors"
                  label="Nombre"
                  required
                ></v-text-field>
              </validation-provider>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="firstContactEmail"
                    name="Correo electrónico"
                    rules="required|email|firstContactEmail:@secondContactEmail|forbiddenUsernames"
                  >
                    <v-text-field
                      v-model="model.firstContactEmail"
                      type="email"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Correo electrónico"
                      required
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </validation-provider>
                </template>
                <span>Correos no permitidos: abuse, admin, avidandlb, billing, compliance, devnull, ftp, hostmaster, inoc, ispfeedback, ispsupport, list, list-request, maildaemon, noc, noreplyno-reply, null, phish, phishing, popmaster, postmaster, privacy, registrar, root, security, soporte, spam, support, sysadmin, tech, undisclosed-recipients, unsubscribe, usenet, uucp, webmaster, webmasters, www</span>
              </v-tooltip>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <validation-provider
                v-slot="{ errors }"
                name="Cargo"
                rules="required|max:80"
                vid="firstContact.occupationDetail"
              >
                <v-text-field
                  v-model="model.firstContactPositionDetail"
                  type="text"
                  outlined
                  dense
                  :error-messages="errors"
                  label="Cargo"
                  required
                ></v-text-field>
              </validation-provider>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="d-flex flex-wrap">
                <div class="flex-grow-1">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Teléfono"
                    vid="firstContactPhone"
                    :rules="{ phone: true }"
                  >
                    <vue-tel-input-vuetify
                      label="Teléfono"
                      mode="international"
                      :error-messages="errors"
                      required
                      outlined
                      dense
                      v-model="model.firstContactPhone"
                      :preferred-countries="['CO', 'US']"
                    ></vue-tel-input-vuetify>
                  </validation-provider>
                </div>
                <div class="flex-grow-1">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Extensión"
                    vid="firstContactPhoneExtension"
                    rules="integer"
                  >
                    <v-text-field
                      v-model="model.firstContactPhoneExtension"
                      type="text"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Extensión"
                      required
                    ></v-text-field>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <validation-provider
                v-slot="{ errors }"
                vid="firstContactMobilePhone"
                name="Teléfono"
                :rules="{ required: true, phone: true }"
              >
                <vue-tel-input-vuetify
                  label="Celular"
                  mode="international"
                  :inputOptions="{ showDialCode: false }"
                  :error-messages="errors"
                  class="flex-wrap"
                  required
                  outlined
                  dense
                  v-model="model.firstContactMobilePhone"
                  :preferred-countries="['CO', 'US']"
                ></vue-tel-input-vuetify>
              </validation-provider>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-12">
              <h3>Segundo contacto para {{ appName }}</h3>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <validation-provider
                v-slot="{ errors }"
                name="Nombre"
                vid="secondContactName"
                rules="required|max:80"
              >
                <v-text-field
                  v-model="model.secondContactName"
                  type="text"
                  outlined
                  dense
                  :error-messages="errors"
                  label="Nombre"
                  required
                ></v-text-field>
              </validation-provider>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Correo electrónico"
                    vid="secondContactEmail"
                    rules="required|email|secondContactEmail:@firstContactEmail|forbiddenUsernames"
                  >
                    <v-text-field
                      v-model="model.secondContactEmail"
                      type="email"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Correo electrónico"
                      required
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </validation-provider>
                </template>
                <span>Correos no permitidos: abuse, admin, avidandlb, billing, compliance, devnull, ftp, hostmaster, inoc, ispfeedback, ispsupport, list, list-request, maildaemon, noc, noreplyno-reply, null, phish, phishing, popmaster, postmaster, privacy, registrar, root, security, soporte, spam, support, sysadmin, tech, undisclosed-recipients, unsubscribe, usenet, uucp, webmaster, webmasters, www</span>
              </v-tooltip>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <validation-provider
                v-slot="{ errors }"
                name="Cargo"
                vid="secondContactPositionDetail"
                rules="required|max:80"
              >
                <v-text-field
                  v-model="model.secondContactPositionDetail"
                  type="text"
                  outlined
                  dense
                  :error-messages="errors"
                  label="Cargo"
                  required
                ></v-text-field>
              </validation-provider>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="d-flex flex-wrap">
                <div class="flex-grow-1">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Teléfono"
                    vid="secondContactPhone"
                    :rules="{ phone: true }"
                  >
                    <vue-tel-input-vuetify
                      label="Teléfono"
                      mode="international"
                      :error-messages="errors"
                      required
                      outlined
                      dense
                      v-model="model.secondContactPhone"
                      :preferred-countries="['CO', 'US']"
                    ></vue-tel-input-vuetify>
                  </validation-provider>
                </div>
                <div class="flex-grow-1">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Extensión"
                    vid="secondContact.phoneExtension"
                    rules="integer"
                  >
                    <v-text-field
                      v-model="model.secondContactPhoneExtension"
                      type="text"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Extensión"
                      required
                    ></v-text-field>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <validation-provider
                v-slot="{ errors }"
                name="Teléfono"
                vid="secondContactMobilePhone"
                :rules="{ required: true, phone: true }"
              >
                <vue-tel-input-vuetify
                  label="Celular"
                  mode="international"
                  :inputOptions="{ showDialCode: false }"
                  :error-messages="errors"
                  required
                  outlined
                  dense
                  v-model="model.secondContactMobilePhone"
                  :preferred-countries="['CO', 'US']"
                ></vue-tel-input-vuetify>
              </validation-provider>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <v-btn
            color="primary"
            class="mr-2 text-none elevation-0"
            @click="saveStep1"
          >
            <span v-if="!hasPermission('EDIT_BOOKING')">Siguiente</span>
            <span v-if="hasPermission('EDIT_BOOKING')">Guardar</span>
          </v-btn>
          <v-btn
            v-if="hasPermission('EDIT_BOOKING')"
            color="default"
            class="mr-2 text-none elevation-0"
            @click="$emit('nextStep', 0)"
          >
            Anterior
          </v-btn>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import sessionMixin from '@/mixins/sessionMixin'
import notificationMixin from '@/mixins/notificationMixin'
import {
  BOOKINGS_URL,
  CITIES_URL,
  COUNTRIES_URL,
  DEPARTMENTS_URL,
  PUBLIC_BOOKINGS_URL
} from '@/constants/ServicesConstants'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import eventHub from '@/eventhub'

export default {
  name: 'BookingInfo',
  props: {
    bookingId: {
      default: null,
      type: String
    }
  },
  async created () {
    eventHub.$on('step1', async () => {
      try {
        this.showLoader()
        await this.getStep1()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    })
  },
  methods: {
    async getCountries () {
      this.departments = []
      this.cities = []
      const response = await this.get(COUNTRIES_URL)
      this.countries = response.data
    },
    async onChangeCountry () {
      try {
        this.showLoader()
        await this.getDepartments()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    async onChangeDepartment () {
      try {
        this.showLoader()
        await this.getCities()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    async getDepartments (countryId) {
      this.cities = []
      this.departments = []
      const response = await this.getById(DEPARTMENTS_URL, this.model.companyCountryId || countryId)
      this.departments = response.data
    },
    async getCities (departmentId) {
      this.cities = []
      const response = await this.getById(CITIES_URL, this.model.companyDepartmentId || departmentId)
      this.cities = response.data
    },
    async getStep1 () {
      if (this.hasPermission('EDIT_BOOKING')) {
        await this.getCountries()
      }
      const response = await this.get(
        BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-1'
      )
      this.model = response.data
      if (this.hasPermission('EDIT_BOOKING')) {
        await Promise.all([
          this.getDepartments(this.model.companyCountryId),
          this.getCities(this.model.companyDepartmentId)
        ])
      }
    },
    async saveStep1 () {
      const result = await this.$refs.form.validate()
      if (result) {
        try {
          await this.get(`${PUBLIC_BOOKINGS_URL}/validate-booking-contacts`, {
            params: {
              firstContactEmail: this.model.firstContactEmail,
              firstContactId: this.model.firstContactId,
              secondContactEmail: this.model.secondContactEmail,
              secondContactId: this.model.secondContactId,
              fairId: this.model.fairId,
              bookingId: this.bookingId
            }
          })
          this.update(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-1', this.model)
          this.$emit('nextStep', 2)
        } catch (e) {
          if (e && e.response && e.response.data.length >= 2) {
            this.showError(e.response.data[1])
          } else {
            this.showError()
          }
        }
      }
    }
  },
  mixins: [
    crudServiceMixin,
    loaderMixin,
    notificationMixin,
    formOperationsMixin,
    sessionMixin
  ],
  components: {},
  data () {
    return {
      appName: process.env.VUE_APP_NAME,
      countries: [],
      departments: [],
      cities: [],
      rutLabel: process.env.VUE_APP_RUT_LABEL,
      model: {
        companyIdentification: '',
        companyBusinessName: '',
        companyEmail: '',
        companyPhone: '',
        companyAddress: '',
        companyWebSite: '',
        companyCountryId: '',
        companyCountryName: '',
        companyDepartmentId: '',
        companyDepartmentName: '',
        companyCityId: '',
        companyCityName: '',
        fairId: null,
        CEOName: '',
        CEOPositionDetail: '',
        CEOEmail: '',
        CEOPhone: '',
        CEOPhoneExtension: '',
        firstContactId: null,
        firstContactName: '',
        fistContactEmail: '',
        firstContactPhone: '',
        firstContactPositionDetail: '',
        firstContactPhoneExtension: '',
        firstContactMobilePhone: '',
        secondContactId: null,
        secondContactName: '',
        secondContactEmail: '',
        secondContactPhone: '',
        secondContactPositionDetail: '',
        secondContactPhoneExtension: '',
        secondContactMobilePhone: ''
      }
    }
  }
}
</script>
