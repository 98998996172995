<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex justify-end">
        <v-btn color="secondary" class="text--primary text-none elevation-0" @click="addCompany">
          Agregar
        </v-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Empresa
              </th>
              <th class="text-left">
                País
              </th>
              <th class="text-left">
                Departamento
              </th>
              <th class="text-left">
                Ciudad
              </th>
              <th>
                Contacto
              </th>
              <th>
                Teléfono
              </th>
              <th>
                Correo corporativo
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(targetCompany,index) in targetCompanies" :key="targetCompany.id">
              <td>{{ targetCompany.name }}</td>
              <td>{{ targetCompany.countryName }}</td>
              <td>{{ targetCompany.departmentName }}</td>
              <td>{{ targetCompany.cityName }}</td>
              <td>{{ targetCompany.contact }}</td>
              <td>{{ targetCompany.contactPhone }} ext {{ targetCompany.contactPhoneExtension }}</td>
              <td>{{ targetCompany.email }}</td>
              <td>
                <v-btn @click="onEdit(targetCompany)" text>
                  editar
                </v-btn>

                <v-btn @click="onRemove(index)" text>
                  eliminar
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <v-btn color="primary" class="mr-2 text-none elevation-0" @click="saveStep10">
          <span v-if="!hasPermission('EDIT_BOOKING')">Siguiente</span>
          <span v-if="hasPermission('EDIT_BOOKING')">Guardar</span>
        </v-btn>
        <v-btn color="default" class="mr-2 text-none elevation-0" @click="$emit('nextStep',9)">
          Anterior
        </v-btn>
      </div>
    </div>

    <target-company-modal ref="targetCompanyModal" :booking-id="bookingId"
                          @onSave="add"></target-company-modal>
  </div>
</template>

<script>
import { BOOKINGS_URL } from '@/constants/ServicesConstants'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import eventHub from '@/eventhub'
import TargetCompanyModal from '@/views/private/booking-confirmation/components/TargetCompanyModal'
import sessionMixin from '@/mixins/sessionMixin'

export default {
  name: 'TargetCompanies',
  components: { TargetCompanyModal },
  props: {
    bookingId: {
      default: null,
      type: String
    }
  },
  async created () {
    eventHub.$on('step10', async () => {
      try {
        this.showLoader()
        await this.getStep10()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    })
  },
  methods: {
    addCompany () {
      this.$refs.targetCompanyModal.open()
    },
    async getStep10 () {
      const response = await this.get(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-10')
      this.targetCompanies = response.data
    },
    saveStep10 () {
      this.create(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-10', { targetCompanies: this.targetCompanies })
        .then(() => {
          this.$emit('nextStep', 11)
        })
    },
    async add (model) {
      this.targetCompanies.push(model)
    },
    onRemove (index) {
      this.targetCompanies.splice(index, 1)
    },
    onEdit (targetCompany) {
      this.$refs.targetCompanyModal.open(targetCompany)
    }
  },
  mixins: [crudServiceMixin, loaderMixin, notificationMixin, formOperationsMixin, sessionMixin],
  data: function () {
    return {
      targetCompanies: []
    }
  }
}
</script>
