<template>
  <div
    class="image-input"
    :style="[!isFile ? {'background-image': `url(${imageUrl})`} : '' ,
    {'width': `${options.width}`, 'height': `${options.height}` }]"
    @click="chooseImage"
  >
    <span
      v-if="isFile"
      class="placeholder"
    >
        <i v-if="!imageError" class="upload-icon fa fa-file-alt"></i>
        <span v-if="!imageError" class="d-block">Archivo Cargado</span>
        <i v-if="imageError" class="upload-icon text--error fa fa-exclamation-triangle"></i>
        <span v-if="imageError" class="d-block text--error">{{ imageError }}</span>
    </span>
    <span
      v-if="!imageUrl && !isFile"
      class="placeholder text-center"
    >
        <i v-if="!imageError" class="upload-icon fa fa-cloud-upload-alt"></i>
        <span v-if="!imageError" class="d-block mx-1">{{ options.description }}</span>
        <i v-if="imageError" class="upload-icon text--error fa fa-exclamation-triangle"></i>
        <span v-if="imageError" class="d-block text--error">{{ imageError }}</span>
    </span>
    <input
      class="file-input"
      ref="fileInput"
      type="file"
      @input="onSelectFile"
    >
  </div>
</template>
<script>
export default {
  name: 'FileChooser',
  props: {
    options: {
      default: () => {
        return {
          description: 'Cargar Archivo',
          width: '100%',
          height: '200px',
          error: 'Solo se permiten imagenes',
          regex: /\.(jpg|jpeg|png|pdf)$/,
          fileSize: 2
        }
      },
      type: Object
    }
  },
  data () {
    return {
      isFile: false,
      imageUrl: null,
      imageError: null
    }
  },
  methods: {
    chooseImage () {
      this.$refs.fileInput.click()
    },
    setError (error) {
      this.imageError = error
    },
    setImage (imageUrl, mobile = false) {
      if (imageUrl) {
        this.imageUrl = (mobile ? process.env.VUE_APP_API_MOBILE_IMAGES_URL : process.env.VUE_APP_API_IMAGES_URL) + imageUrl
      } else {
        this.imageUrl = null
      }
    },
    setIsFile () {
      this.isFile = true
    },
    onSelectFile () {
      this.imageUrl = null
      this.imageError = null
      const input = this.$refs.fileInput
      const files = input.files
      if (files && files[0]) {
        if (this.getFileSize(files[0]) > this.options.fileSize) {
          this.imageError = 'El archivo supera el tamaño maximo de ' + this.options.fileSize + 'MB'
          return
        }
        if (!this.isValidFile(files[0])) {
          this.imageError = this.options.error
          return
        }
        if (this.isImage(files[0])) {
          this.isFile = false
          const reader = new FileReader()
          reader.onload = e => {
            this.imageUrl = e.target.result
          }
          reader.readAsDataURL(files[0])
        } else {
          this.isFile = true
        }
        this.$emit('input', files[0])
        this.$refs.fileInput.value = null
      }
    },
    getFileSize (file) {
      return file.size / 1024 / 1024
    },
    isValidFile (file) {
      return this.options.regex.test(file.name)
    },
    isImage (file) {
      return /\.(jpg|jpeg|png)$/.test(file.name)
    },
    deleteFile () {
      this.isFile = false
    }
  }
}
</script>
<style>

.image-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
  border: 2px dashed rgb(90, 90, 90);
}

.upload-icon {
  font-size: 40px;
  padding: 10px;
}

.text--error {
  color: darkred;
}

.placeholder {
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 15px;
}

.placeholder:hover {
  background: #E0E0E0;
}

.file-input {
  display: none;
}
</style>
