<template>
  <div>
    <validation-observer ref="form">
      <div class="row">
        <div class="col-12 pt-0 pb-0">
          <span class="text-h5 red--text">Esta información será visible al público una vez diligencie completamente el formulario.  Por favor revísala antes de finalizar.</span>
          <h3>Reseña empresarial*</h3>
          <span>La cantidad máxima de caracteres es 400 incluyendo los espacios.</span>
          <v-container fluid>
            <validation-provider
              vid="spanishDescription"
              v-slot="{ errors }"
              name="Reseña"
              rules="required|min:100|max:400"
            >
              <v-textarea
                counter="400"
                outlined
                clearable
                type="text"
                :error-messages="errors"
                clear-icon="mdi-close-circle"
                label="Escribe una breve descripción de la empresa"
                v-model="model.spanishDescription"
              ></v-textarea>
            </validation-provider>
          </v-container>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-0 pb-0">
          <v-btn color="primary" class="mr-2 text-none elevation-0" @click="saveStep5">
            <span v-if="!hasPermission('EDIT_BOOKING')">Siguiente</span>
            <span v-if="hasPermission('EDIT_BOOKING')">Guardar</span>
          </v-btn>
          <v-btn color="default" class="mr-2 text-none elevation-0" @click="$emit('nextStep',4)">
            Anterior
          </v-btn>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>

import { BOOKINGS_URL } from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import eventHub from '@/eventhub'
import sessionMixin from '@/mixins/sessionMixin'

export default {
  created () {
    eventHub.$on('step5', async () => {
      try {
        this.showLoader()
        await this.getStep5()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    })
  },
  data () {
    return {
      model: {
        spanishDescription: ''
      }
    }
  },
  methods: {
    async getStep5 () {
      const response = await this.get(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-5')
      this.booking = response.data
      this.model.spanishDescription = response.data.spanishDescription
    },
    saveStep5 () {
      this.executeWithFormValidation(() => {
        this.create(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-5', this.model).then(() => {
          this.$emit('nextStep', 6)
        })
      })
    }
  },
  mixins: [loaderMixin, notificationMixin, crudServiceMixin, formOperationsMixin, sessionMixin],
  name: 'CompanyReview',
  props: {
    bookingId: {
      default: null,
      type: String
    }
  }
}
</script>
