<template>
  <v-row justify="center">
    <v-dialog
      v-model="isActive"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Compañia de interés</span>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="form">
            <div class="row">
              <div class="col-12 col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Empresa"
                  vid="name"
                  rules="required|max:80"
                >
                  <v-text-field
                    v-model="model.name"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Empresa*"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-12 col-md-6">
                <validation-provider vid="countryId" v-slot="{ errors }" name="País" rules="required">
                  <v-select
                    v-model="model.countryId"
                    :error-messages="errors"
                    :items="countries"
                    @change="onChangeCountry"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    label="País*"
                  ></v-select>
                </validation-provider>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <validation-provider vid="departmentId" v-slot="{ errors }" name="Departamento/estado"
                                     rules="required">
                  <v-select
                    v-model="model.departmentId"
                    :error-messages="errors"
                    :items="departments"
                    @change="onChangeDepartment"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    label="Departamento/estado*"
                  ></v-select>
                </validation-provider>
              </div>
              <div class="col-12 col-md-6">
                <validation-provider vid="cityId" v-slot="{ errors }" name="Ciudad" rules="required">
                  <v-select
                    v-model="model.cityId"
                    :error-messages="errors"
                    :items="cities"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    label="Ciudad*"
                  ></v-select>
                </validation-provider>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Contacto"
                  vid="contact"
                  rules="max:80"
                >
                  <v-text-field
                    v-model="model.contact"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Contacto"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-12 col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Correo corporativo"
                  vid="email"
                  rules="max:80|email"
                >
                  <v-text-field
                    v-model="model.email"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Correo corporativo"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="d-flex flex-wrap">
                  <div class="flex-grow-1">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Teléfono"
                      vid="contactPhone"
                      :rules="{phone: true}"
                    >
                      <vue-tel-input-vuetify
                        label="Teléfono*"
                        mode="international"
                        :error-messages="errors"
                        required
                        outlined
                        dense
                        v-model="model.contactPhone"
                        :preferred-countries="['CO', 'US']"
                      ></vue-tel-input-vuetify>
                    </validation-provider>
                  </div>
                  <div class="flex-grow-1">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Extensión"
                      vid="contactPhoneExtension"
                      rules="integer"
                    >
                      <v-text-field
                        v-model="model.contactPhoneExtension"
                        type="text"
                        outlined
                        dense
                        :error-messages="errors"
                        label="Extensión*"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="save"
          >
            Guardar
          </v-btn>
          <v-btn
            color="red"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { CITIES_URL, COUNTRIES_URL, DEPARTMENTS_URL } from '@/constants/ServicesConstants'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'

export default {
  name: 'TargetCompanyModal',
  props: {
    bookingId: {
      default: null,
      type: String
    }
  },
  async created () {
    try {
      this.showLoader()
      await this.getCountries()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  data () {
    return {
      countries: [],
      departments: [],
      cities: [],
      isActive: false,
      isEdit: false,
      model: {
        cityId: null,
        countryId: null,
        contactPhone: null,
        contactPhoneExtension: null,
        departmentId: null,
        email: null,
        name: null
      }
    }
  },
  mixins: [crudServiceMixin, loaderMixin, notificationMixin, formOperationsMixin],
  methods: {
    async open (targetCompany) {
      this.isActive = true
      if (targetCompany) {
        this.isEdit = true
        try {
          this.showLoader()
          await this.getDepartments(targetCompany.countryId)
          await this.getCities(targetCompany.departmentId)
          this.model = targetCompany
          this.hideLoader()
        } catch {
          this.hideLoader()
          this.showError()
        }
      }
    },
    async getCountries () {
      this.departments = []
      this.cities = []
      const response = await this.get(COUNTRIES_URL)
      this.countries = response.data
    },
    async onChangeCountry () {
      try {
        this.showLoader()
        await this.getDepartments()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    async onChangeDepartment () {
      try {
        this.showLoader()
        await this.getCities()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    async getDepartments (countryId) {
      this.cities = []
      this.departments = []
      const response = await this.getById(DEPARTMENTS_URL, this.model.countryId || countryId)
      this.departments = response.data
    },
    getName (id, array) {
      const element = array.find(e => e.id === id)
      if (element) {
        return element.name
      }
      return ''
    },
    async getCities (departmentId) {
      this.cities = []
      const response = await this.getById(CITIES_URL, this.model.departmentId || departmentId)
      this.cities = response.data
    },
    save () {
      this.executeWithFormValidation(() => {
        if (!this.isEdit) {
          this.$emit('onSave', {
            ...this.model,
            cityName: this.getName(this.model.cityId, this.cities),
            departmentName: this.getName(this.model.departmentId, this.departments),
            countryName: this.getName(this.model.countryId, this.countries)
          })
        }
        this.$refs.form.reset()
        this.model = {}
        this.isActive = false
      })
    },
    close () {
      this.$refs.form.reset()
      this.model = {}
      this.isActive = false
    }
  }
}
</script>
